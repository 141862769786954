import { isDefined } from '~/utils'

import type { ProfileType, SsoProvider } from '~/types/Auth'
import type { AuthFetch } from '~/types/globalDeclarations/AuthFetch'
import type { Locale } from '~/types/locale'

type Payload = {
  email: string
  password1: string
  password2: string
  lang: Locale
  first_name: string
  last_name?: string
  profile_type?: ProfileType
  accepted_tos: boolean
}

export type RegisterUserSuccess = {
  user: {
    accepted_tos: false
    allowed_services: ['core']
    email: string
    first_name: string
    lang: Locale
    last_name: string
    profile_type: ProfileType | null
    social_accounts: []
    /**
     * Authentification service unique id bound to the user.
     */
    uid: string
  }
  access: string
  refresh: string
}

/**
 * When a registration fails the backend auth service returns a formated message describing the issue.
 * If the issue is from a specific field it will be stored in the same key.
 * In case it's anything else the `non_field_errors` key will be used instead.
 */
type RegisterUserError = {
  non_field_errors?: string[]
} & Record<keyof Payload, string[] | undefined>

/**
 * Simple typeguard to check for registration failure.
 *
 * @param response - The api call response. At this stage it can be success or error.
 * @returns `true` if it's an error.
 */
export function registerCallFailed(
  response: unknown,
): response is RegisterUserError {
  // @ts-expect-error trust typeguard
  return !isDefined(response?.user)
}

/**
 * Provide a function call to register on the authentification backend with email and password.
 * You can check for failure case with the `registerCallFailed` function.
 *
 * @param $authFetch - The transporter.
 * @returns The api call function.
 */
export function provideAuthApiRegister($authFetch: AuthFetch) {
  return function (payload: Payload) {
    return $authFetch.$post<RegisterUserSuccess>('/api/registration/', payload)
  }
}

/**
 * Provide a function call to register on the authentification backend with any of our supported SSO's.
 * You can check for failure case with the `registerCallFailed` function.
 *
 * @param $authFetch - The transporter.
 * @returns The api call function.
 */
export function provideAuthApiSsoRegister($authFetch: AuthFetch) {
  return function <T extends SsoProvider>(
    provider: T,
    payload: T extends 'soundcloud'
      ? {
          code: string
          lang: Locale
          email: string
          accepted_tos?: boolean
          profile_type?: ProfileType
        }
      : {
          code: string
          lang: Locale
          accepted_tos?: boolean
          profile_type?: ProfileType
        },
  ) {
    return $authFetch.$post<RegisterUserSuccess>(
      `/api/sso/${provider}/signup/`,
      payload,
    )
  }
}
